import { type ReactNode, useEffect, useRef, useState, useMemo } from 'react';

import vacancyResponseButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_button_click';
import vacancyResponseScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_screen_shown';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseForm, { getUserResumes } from 'src/components/VacancyResponseForm';
import type Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';

import { ResponseStep } from 'src/components/VacancyResponsePopup/BottomSheet/responseSteps';

interface MagrittePopupProps {
    visible: boolean;
    vacancyId: number;
    vacancySource: Source;
    isQuestionResponse?: boolean;
    startedWithQuestion?: boolean;
    onClose: (cancel: boolean) => void;
}

const TrlKeys = {
    titleCommon: 'vacancy.response.popup.title',
    titleRepeat: 'vacancy.response.popup.title.again',
    titleLetter: 'vacancy.response.popup.letter',
    titleQuestion: 'vacancy.questionResponse.popup.title',
    titleQuestionCompact: 'vacancy.questionResponse.magrittePopup.title.compact',
    descriptionQuestion: 'vacancy.questionResponse.popup.description',
    descriptionQuestionCompact: 'vacancy.questionResponse.magrittePopup.description.compact',
    actionSubmitCommon: 'vacancy.response.popup.submit',
    actionSubmitRepeat: 'vacancy.response.popup.submitAgain',
    actionCancel: 'vacancy.response.popup.cancel',
    actionSendQuestion: 'vacancy.questionResponse.magrittePopup.submit',
    actionAddLetter: 'vacancy.response.popup.letter.button.add',
    actionSaveLetter: 'vacancy.response.popup.letter.button.save',
};

const MagrittePopup: TranslatedComponent<MagrittePopupProps> = ({
    trls,
    visible,
    vacancyId,
    vacancySource,
    isQuestionResponse,
    startedWithQuestion,
    onClose,
}) => {
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const vacancy = responseStatus.shortVacancy;
    const vacancyName = responseStatus.shortVacancy.name;
    const isFirstResponse = responseStatus.usedResumeIds.length === 0;
    const isResponseImpossible = !responseStatus.unusedResumeIds.length;
    const [responseErrorCode, setResponseErrorCode] = useState<string | null>(null);

    const [submitting, setSubmitting] = useState(false);

    const { isMobile } = useBreakpoint();

    const lastResumeHash = useSelector((state) => state.lastVacancyResponseInfo?.resumeHash);
    const resumes = useMemo(() => getUserResumes(responseStatus), [responseStatus]);
    const [selectedResume, setSelectedResume] = useState<ResponseStatusResume>(
        resumes.find((resume) => resume.hash === lastResumeHash) || resumes[0]
    );

    const [responseStep, setResponseStep] = useState(ResponseStep.Initial);
    const postponedActions = useRef<(() => void)[]>([]);

    const isLetterRequired = vacancy['@responseLetterRequired'];

    useEffect(() => {
        if (visible) {
            vacancyResponseScreenShown({
                screenType: 'regular',
                responseType: startedWithQuestion ? 'question' : 'simple',
                vacancyId: `${vacancyId}`,
                hhtmSource: 'vacancy_response',
            });
        }
    }, [vacancyId, startedWithQuestion, visible]);

    const handleClose = (isResponse = false): void => {
        if (isResponse) {
            setTimeout(() => {
                postponedActions.current.forEach((action) => action());
                postponedActions.current = [];
            }, 300);
        }

        onClose(!isResponse);
    };

    const handleButtonClick = () => {
        vacancyResponseButtonClick({
            buttonName: 'vacancy_response',
            screenType: 'regular',
            responseType: startedWithQuestion ? 'question' : 'simple',
            vacancyId: `${vacancyId}`,
            hhtmSource: 'vacancy_response',
        });
    };

    const renderModalSubmitButton = (form: string, isLetterTextEmpty: boolean) => {
        return (
            <Button
                form={form}
                type="submit"
                mode="primary"
                style="accent"
                data-qa="vacancy-response-submit-popup"
                disabled={
                    isResponseImpossible ||
                    submitting ||
                    ((isLetterRequired || isQuestionResponse) && isLetterTextEmpty)
                }
                loading={submitting}
                onClick={handleButtonClick}
            >
                {isQuestionResponse
                    ? trls[TrlKeys.actionSendQuestion]
                    : trls[isFirstResponse ? TrlKeys.actionSubmitCommon : TrlKeys.actionSubmitRepeat]}
            </Button>
        );
    };

    const renderModal: (props: {
        renderForm: () => ReactNode;
        formId: string;
        isLetterTextEmpty: boolean;
    }) => ReactNode = (props) => (
        <>
            <Modal
                visible={!isMobile && visible}
                title={trls[isQuestionResponse ? TrlKeys.titleQuestion : TrlKeys.titleCommon]}
                titleSize="large"
                titleMaxLines={1}
                titleDescription={isQuestionResponse ? trls[TrlKeys.descriptionQuestion] : vacancyName}
                titleDescriptionStyle="secondary"
                titleDescriptionMaxLines={1}
                actions={
                    <Action
                        icon={CrossOutlinedSize24}
                        mode="secondary"
                        aria-label={trls[TrlKeys.actionCancel]}
                        onClick={() => handleClose()}
                    />
                }
                rightButtons={renderModalSubmitButton(props.formId, props.isLetterTextEmpty)}
                leftButtons={
                    <Button mode="tertiary" style="accent" onClick={() => handleClose()}>
                        {trls[TrlKeys.actionCancel]}
                    </Button>
                }
                onClose={() => handleClose()}
            >
                {props.renderForm()}
            </Modal>
            <BottomSheet
                visible={isMobile && visible}
                header={
                    <NavigationBar
                        title={trls[isQuestionResponse ? TrlKeys.titleQuestionCompact : TrlKeys.titleCommon]}
                        subtitle={isQuestionResponse ? trls[TrlKeys.descriptionQuestionCompact] : vacancyName}
                        right={<Action icon={CrossOutlinedSize24} onClick={() => handleClose()} />}
                        showDivider="always"
                    />
                }
                footer={
                    <BottomSheetFooter>
                        {renderModalSubmitButton(props.formId, props.isLetterTextEmpty)}
                    </BottomSheetFooter>
                }
                onClose={() => handleClose()}
                onAfterExit={() => {
                    setResponseStep(ResponseStep.Initial);
                }}
            >
                {props.renderForm()}
            </BottomSheet>
        </>
    );

    return (
        <VacancyResponseForm
            isQuestionResponse={isQuestionResponse}
            isFromPopup
            vacancyId={vacancyId}
            resumes={resumes}
            submitting={submitting}
            setSubmitting={setSubmitting}
            responseStep={responseStep}
            onResponse={() => handleClose(true)}
            errorCode={responseErrorCode ?? ''}
            onError={setResponseErrorCode}
            vacancySource={vacancySource}
            isBottomSheet={isMobile}
            selectedResume={selectedResume}
            setSelectedResume={setSelectedResume}
            postponedActions={postponedActions}
            render={renderModal}
        />
    );
};

export default translation(MagrittePopup);
