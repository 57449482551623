import { Tag } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { ExperienceOption } from 'src/models/search/searchCriteria.types';
import { ScheduleLowerCase } from 'src/utils/constants/schedule';

const TrlKeys = {
    experience: 'vacancy.experience.title',
    [ExperienceOption.NoExperience]: 'vacancy.experience.noExperience',
    [ExperienceOption.Between1And3]: 'vacancy.experience.between1And3',
    [ExperienceOption.Between3And6]: 'vacancy.experience.between3And6',
    [ExperienceOption.MoreThan6]: 'vacancy.experience.moreThan6',
    canWorkRemotely: 'label.workSchedule.canWorkRemotely',
};

interface ExperienceAndRemoteProps {
    workExperience: ExperienceOption;
    workSchedule: ScheduleLowerCase;
}

const ExperienceAndRemote: TranslatedComponent<ExperienceAndRemoteProps> = ({ workExperience, workSchedule, trls }) => {
    const isRemoteAllowedLabelShown = workSchedule === ScheduleLowerCase.Remote;

    if (!workExperience && !isRemoteAllowedLabelShown) {
        return null;
    }

    const experienceText =
        workExperience === ExperienceOption.NoExperience
            ? trls[TrlKeys[workExperience]]
            : format(trls[TrlKeys.experience], { '{0}': trls[TrlKeys[workExperience]] });

    return (
        <>
            <Tag data-qa="vacancy-serp__vacancy-work-experience">{experienceText}</Tag>
            {isRemoteAllowedLabelShown && (
                <Tag data-qa="vacancy-label-remote-work-schedule">{trls[TrlKeys.canWorkRemotely]}</Tag>
            )}
        </>
    );
};

export default translation(ExperienceAndRemote);
